import React, { memo } from "react"
import PropTypes from "prop-types"
import classes from "./Input.module.scss"
import HelperText from "./HelperText"

const CheckBoxInput = ({
  register,
  hasError,
  name,
  label,
  helperText,
  validation,
  values,
}) => (
  <div className={classes.root}>
    {label && <label className={classes.label}>{label}</label>}
    <div className={classes.checkboxWrapper}>
      {values.map(({ label }, index) => {
        return (
          <div key={index} className={classes.checkbox}>
            <input
              type="checkbox"
              id={index}
              value={label}
              name={name}
              ref={register(validation)}
            />
            <label htmlFor={index}> {label} </label>
          </div>
        )
      })}
    </div>
    {helperText && (
      <div className="pt-1">
        <HelperText isError={hasError} message={helperText} />
      </div>
    )}
  </div>
)

CheckBoxInput.propTypes = {
  register: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validation: PropTypes.shape({}),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
}

CheckBoxInput.defaultProps = {
  validation: {},
  helperText: null,
}

export default memo(CheckBoxInput)
