import React, { memo } from "react"
import classes from "src/components/layout/DefaultHeader/DefaultHeader.module.scss"
import NavItem from "../NavItem/NavItem"
import clsx from "clsx"

const HeaderSubMenu = ({ subMenu, active }) => {
  return (
    <ul className={clsx(classes.subMenu, { [classes.activeSub]: active })}>
      {subMenu.map(({ ...props }, index) => (
        <NavItem key={index} {...props} />
      ))}
    </ul>
  )
}

export default memo(HeaderSubMenu)
