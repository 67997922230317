import React, { useContext } from "react"
import ContactForm from "../../forms/ContactForm/ContactForm"
import VacancyForm from "src/components/forms/VacancyForm/VacancyForm"
import { FormOpenContext } from "../BasicLayout/BasicLayout"
import classes from "../../forms/Forms.module.scss"
import clsx from "clsx"
import fingerClick from "../../../images/fingerClick.gif"

const FormsCallBack = () => {
  let {
    isOpenContactForm,
    handleCloseContactForm,
    isOpenCV,
    isFormSentSuccessfully,
    setIsFormSentSuccessfully,
    formName,
    setFormName,
  } = useContext(FormOpenContext)

  return (
    <>
      <div
        onClick={handleCloseContactForm}
        className={isOpenContactForm ? classes.back : ""}
      >
        <div
          className={clsx(classes.root, { [classes.open]: isOpenContactForm })}
          id="root"
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {isOpenCV ? (
            <VacancyForm
              isFormSentSuccessfully={isFormSentSuccessfully}
              setIsFormSentSuccessfully={setIsFormSentSuccessfully}
              open={isOpenContactForm}
              onClose={handleCloseContactForm}
            />
          ) : (
            <ContactForm
              isFormSentSuccessfully={isFormSentSuccessfully}
              setIsFormSentSuccessfully={setIsFormSentSuccessfully}
              open={isOpenContactForm}
              onClose={handleCloseContactForm}
              formName={formName}
              setFormName={setFormName}
            />
          )}
        </div>
        <img
          style={{ display: "none" }}
          src={fingerClick}
          alt="Preload gif with Tanos"
        />
      </div>
    </>
  )
}

export default FormsCallBack
