import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import classes from "./Input.module.scss"

const HelperText = ({ message, isError, className }) => {
  let messageList = message

  if (!Array.isArray(message)) {
    messageList = [message]
  }

  return messageList.map(item => (
    <div
      key={`helperText-${message}`}
      className={clsx(classes.helperText, className, {
        [classes.error]: isError,
      })}
    >
      {item}
    </div>
  ))
}

HelperText.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
}

HelperText.defaultProps = {
  className: undefined,
}

export default HelperText
