import React from "react"
import classes from "./Button.module.scss"
import { Link } from "gatsby"
import classnames from "classnames"

const Button = ({
  onClick,
  isActive,
  children,
  className,
  id,
  isLink = false,
}) => {
  return isLink ? (
    <Link
      to="/"
      className={classnames(className, classes.Btn, {
        [classes["Btn__active"]]: isActive,
      })}
    >
      {children}
    </Link>
  ) : (
    <button
      id={id}
      className={classnames(className, classes.Btn, {
        [classes["Btn__active"]]: isActive,
      })}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
