import React, { forwardRef, memo, useState } from "react"
import classes from "./Calendar.module.scss"
import DatePicker from "react-datepicker"
import "./react-datepicker.css"
import Image from "../../image"
import CustomHeader from "./components/CustomHeader"

const Calendar = () => {
  const minDate = new Date("1000")
  const maxDate = new Date("3000")
  const [date, setDate] = useState(new Date("1831-07-07"))

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div>
      <input onClick={onClick} ref={ref} value={value} readOnly />
      <Image
        src="calendar.png"
        onClick={onClick}
        className={classes.pictureCalendar}
      />
    </div>
  ))

  const handleChangeCalendarDate = date => {
    setDate(date)
  }

  return (
    <div className={classes.calendarWrapper}>
      <div id="calendar">
        <DatePicker
          calendarClassName={classes.calendar}
          renderCustomHeader={info => <CustomHeader date={date} {...info} />}
          selected={date}
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleChangeCalendarDate}
          dateFormat="MMM.dd.yyy"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          customInput={<ExampleCustomInput />}
          dropdownMode="select"
        />
      </div>
    </div>
  )
}

export default memo(Calendar)
