import React from "react"
import PropTypes from "prop-types"
import SvgIcon from "src/components/SvgIcon"
import Facebook from "src/assets/icons/facebook.svg"
import Instagram from "src/assets/icons/instagram.svg"
import Skype from "src/assets/icons/skype.svg"
import LinkedIn from "src/assets/icons/linkedin.svg"
import Phone from "src/assets/icons/phone.svg"
import classes from "./SocialLinks.module.scss"

const SocialLinks = ({ hide, margin, mobile }) => {
  if (hide) {
    return null
  }

  return (
    <div
      className={`${mobile ? classes.linksWrapperMob : classes.linksWrapperDesk}
      ${margin && `mb-7`}`}
    >
      <a
        href="https://www.facebook.com/drum.n.code.it"
        id="facebookLink"
        target="_blank"
        aria-label="Facebook"
        rel="noopener noreferrer"
        className={classes.socialLink}
      >
        <SvgIcon icon={Facebook} />
      </a>
      <a
        href="https://www.instagram.com/drum.n.code.it"
        id="InstagramLink"
        target="_blank"
        aria-label="Instagram"
        rel="noopener noreferrer"
        className={classes.socialLink}
      >
        <SvgIcon icon={Instagram} />
      </a>
      <a
        aria-label="Skype"
        id="SkypeLink"
        href="skype:live:e4187abd1073736c?chat"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.socialLink}
      >
        <SvgIcon icon={Skype} />
      </a>
      <a
        href="https://www.linkedin.com/company/drumncode"
        id="LinkedInLink"
        aria-label="LinkedIn"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.socialLink}
      >
        <SvgIcon icon={LinkedIn} />
      </a>
      <a
        className={classes.socialLink}
        id="callUsLink"
        aria-label="callUs"
        href="tel:+380953159373"
      >
        <SvgIcon icon={Phone} />
      </a>
    </div>
  )
}

SocialLinks.propTypes = {
  hide: PropTypes.bool,
}

SocialLinks.defaultProps = {
  hide: false,
}

export default SocialLinks
