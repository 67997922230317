import React, { useCallback, useState } from "react"
import TextInputWrapper from "src/components/forms/components/wrappers/TextInputWrapper"
import SubmitButton from "src/components/forms/components/inputs/SubmitButton"
import {
  FileInputDefaultSubscription,
  validation,
} from "src/components/forms/constants"
import { useForm, FormProvider } from "react-hook-form"
import { ClearAllErrors, clearErrorByName } from "src/constants/functions"
import TextAreaWrapper from "../../components/wrappers/TextAreaWrapper"
import FileInputWrapper from "../../components/wrappers/FileInputWrapper"
import PropTypes from "prop-types"
import classes from "../../Forms.module.scss"
import ErrorWrapper from "../../components/ScreenError"

const FormSendCV = ({
  setIsFormSentSuccessfully,
  setFormName,
  setIsCrazyFormSend,
}) => {
  const handleDeleteFile = useCallback(e => {
    document.getElementById("CV_uploader").value = ""
    e.preventDefault()
    setChosenFile(FileInputDefaultSubscription)
  }, [])
  const [isError, setIsError] = useState(false)

  const [chosenFile, setChosenFile] = useState(FileInputDefaultSubscription)
  const formMethods = useForm({ mode: "onChange" })

  const clearData = useCallback(() => {
    setChosenFile(FileInputDefaultSubscription)
    formMethods.reset({})
  }, [setChosenFile, formMethods])

  const submitSendForm = useCallback(
    async ({ name, email, message }) => {
      const formData = new FormData()
      formData.append("name", name)
      formData.append("email", email)
      formData.append("message", message)
      formData.append("CVfile", document.getElementById("CV_uploader").files[0])
      try {
        const result = await fetch(
          `${process.env.GATSBY_HOST_URL}/scripts/sendCVToEmail.php`,
          {
            method: "POST",
            mode: "cors",
            body: formData,
          },
        )

        if (!result.ok) {
          throw new Error(`${result.status} ${result.statusText}`)
        }

        clearData()
        setFormName && setFormName("")
        setIsFormSentSuccessfully(true)
        setIsCrazyFormSend && setIsCrazyFormSend(false)
      } catch (e) {
        setIsError(true)
      }
    },
    [clearData, setFormName, setIsCrazyFormSend, setIsFormSentSuccessfully],
  )

  const handleBlur = useCallback(
    e => clearErrorByName(e, formMethods.clearErrors),
    [formMethods.clearErrors],
  )

  return (
    <FormProvider {...formMethods}>
      <form
        style={{ display: isError ? "none" : "block" }}
        className={classes.formWrapper}
        onSubmit={formMethods.handleSubmit(submitSendForm)}
        encType="multipart/form-data"
      >
        <TextInputWrapper
          type="text"
          name="name"
          label="What's your name?"
          placeholder="Enter your name"
        />
        <TextInputWrapper
          type="email"
          name="email"
          label="Enter your email"
          placeholder="user@example.com"
          validation={validation.email}
          onBlur={e => clearErrorByName(e, formMethods.clearErrors)}
        />
        <TextAreaWrapper
          name="message"
          label="Ask your question"
          maxLength={150}
          placeholder="Your text"
          control={formMethods.control}
        />
        <FileInputWrapper
          FileInputDefaultSubscription={FileInputDefaultSubscription}
          deleteFile={handleDeleteFile}
          chosenFile={chosenFile}
          setChosenFile={setChosenFile}
          name="CV"
          label="Upload  your CV"
          validation={validation.fileCV}
          accept=".pdf,.doc,.docx"
          onBlur={handleBlur}
        />
        <SubmitButton
          disabled={!formMethods.formState.isValid}
          onClick={() => ClearAllErrors(formMethods.clearErrors)}
        >
          Send CV
        </SubmitButton>
      </form>
      <ErrorWrapper
        isError={isError}
        handleSubmit={() => {
          setIsError(false)
        }}
      />
    </FormProvider>
  )
}

FormSendCV.propTypes = {
  setIsFormSentSuccessfully: PropTypes.func.isRequired,
  setFormName: PropTypes.func,
  setIsCrazyFormSend: PropTypes.func,
}

export default FormSendCV
