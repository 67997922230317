import React, { memo, useState } from "react"
import PropTypes from "prop-types"
import classes from "./Input.module.scss"
import Close from "assets/icons/yellowClose.svg"
import HelperText from "./HelperText"
import SvgIcon from "../../../SvgIcon"
import { TimeClearError } from "src/constants/functions"
import classNames from "classnames"

const FileInput = ({
  register,
  hasError,
  type,
  name,
  label,
  validation,
  helperText,
  chosenFile,
  setChosenFile,
  FileInputDefaultSubscription,
  deleteFile,
  isDark,
  isMoreSpace,
  ...rest
}) => {
  const [isFileLoadError, setIsFileLoadError] = useState("")
  const removeError = () => {
    setTimeout(() => {
      clearError()
    }, TimeClearError)
  }
  const clearError = () => {
    setIsFileLoadError("")
    setChosenFile(FileInputDefaultSubscription)
  }

  const setError = (event, error) => {
    setIsFileLoadError(error)
    setChosenFile(FileInputDefaultSubscription)
    event.currentTarget.value = ""
    removeError()
  }

  const fileChange = e => {
    if (e.currentTarget.files[0]) {
      if (e.currentTarget.files[0].size > 10000000) {
        setError(e, "The file has to be less than 10MB.")
        return
      }
      const fileName = e.currentTarget.files[0].name
      const extension = fileName.slice(fileName.lastIndexOf("."))
      if (
        extension !== ".docx" &&
        extension !== ".doc" &&
        extension !== ".pdf"
      ) {
        setError(e, "Unsupported extension")
        return
      }
      setIsFileLoadError("")
      fileName.length > 20
        ? setChosenFile(fileName.slice(0, 20) + "...")
        : setChosenFile(fileName)
    } else {
      clearError()
    }
  }

  return (
    <div
      className={classNames(classes.root, isMoreSpace && classes.rootMoreSpace)}
    >
      {label && (
        <span
          className={classNames(classes.label, isDark && classes.labelDark)}
        >
          {label}
        </span>
      )}

      <div
        className={
          !isDark ? classes.inputFileWrapper : classes.inputFileWrapper__isDark
        }
        style={
          isFileLoadError || hasError
            ? { borderColor: "#F44336" }
            : { borderColor: "#A1A1A1" }
        }
      >
        <input
          id="CV_uploader"
          type="file"
          className={classes.inputFile}
          name={name}
          ref={register(validation)}
          {...rest}
          onChange={fileChange}
        />
        <label htmlFor="CV_uploader" className={classes.labelUpload}>
          <div className={classes.labelText}>
            <span>{chosenFile}</span>
            <SvgIcon
              icon={Close}
              className={
                chosenFile !== FileInputDefaultSubscription
                  ? classes.fileDeleter
                  : classes.hideFileDeleter
              }
              size={"xs"}
              onClick={deleteFile}
            />
          </div>
        </label>
      </div>

      {(helperText || isFileLoadError) && (
        <div className="pt-1">
          <HelperText
            isError={hasError || isFileLoadError}
            message={helperText || isFileLoadError}
          />
        </div>
      )}
    </div>
  )
}

FileInput.propTypes = {
  register: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validation: PropTypes.shape({}),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isDark: PropTypes.bool,
  isMoreSpace: PropTypes.bool,
}

FileInput.defaultProps = {
  validation: {},
  helperText: null,
}

export default memo(FileInput)
