import React, { memo } from "react"
import PropTypes from "prop-types"
import classes from "./Input.module.scss"
import HelperText from "./HelperText"
import classNames from "classnames"

const TextInput = ({
  register,
  hasError,
  type,
  name,
  label,
  validation,
  helperText,
  isDark,
  isMoreSpace,
  ...rest
}) => (
  <div
    className={classNames(classes.root, isMoreSpace && classes.rootMoreSpace)}
  >
    {label && (
      <label className={classNames(classes.label, isDark && classes.labelDark)}>
        {label}
      </label>
    )}
    <input
      className={classNames(classes.input, isDark && classes.inputDark)}
      type={type}
      name={name}
      ref={register(validation)}
      {...rest}
    />
    {helperText && (
      <div className="pt-1">
        <HelperText isError={hasError} message={helperText} />
      </div>
    )}
  </div>
)

TextInput.propTypes = {
  register: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  validation: PropTypes.shape({}),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isDark: PropTypes.bool,
  isMoreSpace: PropTypes.bool,
}

TextInput.defaultProps = {
  type: "text",
  validation: {},
  helperText: null,
}

export default memo(TextInput)
