import React, { memo } from "react"
import PropTypes from "prop-types"
import classes from "./Input.module.scss"
import HelperText from "./HelperText"
import classNames from "classnames"

const SelectInput = ({
  register,
  hasError,
  name,
  label,
  helperText,
  validation,
  options,
  defaultValue,
  value,
  onChange,
  isDark,
  isMoreSpace,
}) => (
  <div
    className={classNames(classes.root, isMoreSpace && classes.rootMoreSpace)}
  >
    {label && (
      <label className={classNames(classes.label, isDark && classes.labelDark)}>
        {label}
      </label>
    )}
    <div className={classes.selectInputWrapper}>
      <select
        className={classNames(classes.input, isDark && classes.inputDark)}
        name={name}
        ref={register(validation)}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
      >
        {options.map(({ label, value }) => (
          <option
            className={isDark && classes.optionDark}
            key={value}
            value={value}
          >
            {label}
          </option>
        ))}
      </select>
      <div className={classes.caret} />
    </div>
    {helperText && (
      <div className="pt-1">
        <HelperText isError={hasError} message={helperText} />
      </div>
    )}
  </div>
)

SelectInput.propTypes = {
  register: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validation: PropTypes.shape({}),
  helperText: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isDark: PropTypes.bool,
  isMoreSpace: PropTypes.bool,
}

SelectInput.defaultProps = {
  validation: {},
  defaultValue: undefined,
  helperText: null,
}

export default memo(SelectInput)
