import React, { memo } from "react"
import classes from "../Forms.module.scss"
import Image from "../../image"
import PropTypes from "prop-types"
import ButtonTryAgain from "./ButtonTryAgain"

const ErrorWrapper = ({ isError, handleSubmit }) => {
  return (
    <div
      className={classes.errorWrapper}
      style={{ display: !isError && "none" }}
    >
      <div className={classes.infoWrapper}>
        <Image src="teethSmile.png" className={classes.pictureSuccess} />
        <h3 className={classes.successDescription}>Something wrong!</h3>
      </div>

      {isError && (
        <div className={classes.errorButtonWrapper}>
          <ButtonTryAgain disabled={false} onClick={handleSubmit}>
            Try Again
          </ButtonTryAgain>
        </div>
      )}
    </div>
  )
}

ErrorWrapper.propTypes = {
  isError: PropTypes.bool,
  handleSubmit: PropTypes.func,
}

export default memo(ErrorWrapper)
