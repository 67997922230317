export const TimeClearError = 5000

export const clearErrorByName = (e, clearErrors) => {
  const name = e.target.name
  setTimeout(() => {
    clearErrors(name)
  }, TimeClearError)
}

export const ClearAllErrors = clearErrors => {
  setTimeout(() => {
    clearErrors()
  }, TimeClearError)
}
