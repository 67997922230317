import classes from "../Calendar.module.scss"
import SvgIcon from "../../../SvgIcon"
import arrowLeftIcon from "../../../../assets/icons/arrow-left.svg"
import arrowRightIcon from "../../../../assets/icons/arrow-right.svg"
import SelectInputWrapper from "../../../forms/components/wrappers/SelectInputWrapper"
import { monthsSelect } from "../data"
import React from "react"

const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const years = Array.from({ length: 2000 }, (item, i) => ({
    value: `${1000 + i}`,
    label: `${1000 + i}`,
  }))

  const months = monthsSelect.map(item => item.value)
  const title =
    months[new Date(date).getMonth()] + " " + new Date(date).getFullYear()

  const handleChangeMonth = ({ target: { value } }) => {
    changeMonth(months.indexOf(value))
  }

  const handleChangeYear = ({ target: { value } }) => changeYear(value)

  return (
    <div className={classes.calendarHeaderContainer}>
      <div className={classes.calendarHeaderInfoContainer}>
        <div
          className={classes.svgContainer}
          onClick={!prevMonthButtonDisabled ? decreaseMonth : null}
        >
          <SvgIcon icon={arrowLeftIcon} />
        </div>
        <div className={classes.calendarInfo}>{title}</div>
        <div
          onClick={!nextMonthButtonDisabled ? increaseMonth : null}
          className={classes.svgContainer}
        >
          <SvgIcon icon={arrowRightIcon} />
        </div>
      </div>
      <div className={classes.inputs}>
        <SelectInputWrapper
          label=""
          name="months"
          options={monthsSelect}
          value={months[new Date(date).getMonth()]}
          onChange={handleChangeMonth}
        />
        <SelectInputWrapper
          label=""
          name="years"
          options={years}
          value={new Date(date).getFullYear().toString()}
          onChange={handleChangeYear}
        />
      </div>
    </div>
  )
}

export default CustomHeader
