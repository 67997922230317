import React, { useMemo } from "react"
import PropTypes from "prop-types"

const sizeConfig = {
  xl: 40,
  lg: 32,
  md: 24,
  sm: 16,
  xs: 12,
}

const SvgIcon = ({ icon: Icon, size, ...props }) => {
  const widthHeight = useMemo(() => {
    const number = sizeConfig[size]

    if (number) {
      return {
        width: number,
        height: number,
      }
    }

    return {}
  }, [size])

  return <Icon {...widthHeight} {...props} />
}

SvgIcon.propTypes = {
  icon: PropTypes.func.isRequired,
  size: PropTypes.oneOf(Object.keys(sizeConfig)),
}

SvgIcon.defaultProps = {
  size: "md",
}

export default SvgIcon
