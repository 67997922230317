import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import SvgIcon from "src/components/SvgIcon"
import Back from "src/assets/icons/back.svg"
import Close from "src/assets/icons/close.svg"
import classes from "../Forms.module.scss"
import FormSentSuccessfully from "../components/ScreenFormSendedSuccessfully"
import FormSendCV from "./components/FormSendCV"
import ChatForm from "./components/ChatForm"
import NeedRockForm from "./components/NeedRockForm"
import TestForm from "./components/TestForm/TestForm"
import SocialLinks from "../../SocialLinks/SocialLinks"

const ContactForm = ({
  onClose,
  isFormSentSuccessfully,
  setIsFormSentSuccessfully,
  formName,
  setFormName,
}) => {
  const [reCaptchaToken, setReCaptchaToken] = useState(null)
  const [isCrazyFormSend, setIsCrazyFormSend] = useState(false)
  const [choosedInput, setChoosedInput] = useState("")

  const reCaptchaVerify = useCallback(token => {
    setReCaptchaToken(token)
  }, [])

  const backToFirstStep = useCallback(() => {
    setFormName("")
    setReCaptchaToken(null)
    setChoosedInput("")
  }, [setFormName])

  const handleCloseForm = useCallback(() => {
    setFormName("")
    setChoosedInput("")
    onClose()
  }, [onClose, setFormName])

  useEffect(() => {
    if (!formName) {
      setChoosedInput("")
    }
  }, [formName])

  const choseTheForm = e => {
    const value = e.target.value
    setChoosedInput(value)
    if (e.target.tagName === "INPUT") {
      setTimeout(() => {
        setFormName(value)
      }, 150)
    }
  }

  return (
    <div className={classes.formChosenWrapper}>
      <div className={classes.closWrapper}>
        {formName === "" || isFormSentSuccessfully ? (
          <SvgIcon
            icon={Close}
            className={classes.closeForm}
            onClick={handleCloseForm}
          />
        ) : (
          <SvgIcon
            icon={Back}
            className="cursor-pointer"
            onClick={backToFirstStep}
          />
        )}
      </div>
      {isFormSentSuccessfully ? (
        isCrazyFormSend ? (
          <FormSentSuccessfully
            imageName="crazysmile.png"
            subscription="Your message hasn't been sent"
          />
        ) : (
          <FormSentSuccessfully />
        )
      ) : (
        <>
          <div
            style={{ display: !formName ? "flex" : "none" }}
            className={classes.formContactWrapper}
          >
            <h2 className={`${classes.title} mb-4`}>What's your goal?</h2>
            <div className={classes.inputsWrapper} onChange={choseTheForm}>
              <div className={classes.radioButtonWrapper}>
                <input
                  type="radio"
                  id="needDevsForm"
                  name="goal"
                  value="needRock"
                  checked={choosedInput === "needRock"}
                />
                <label htmlFor="needDevsForm">
                  I need rock star developers
                </label>
              </div>
              <div className={classes.radioButtonWrapper}>
                <input
                  type="radio"
                  id="joinTeamForm"
                  name="goal"
                  value="joinTeam"
                  checked={choosedInput === "joinTeam"}
                />
                <label htmlFor="joinTeamForm">I want to join your team</label>
              </div>
              <div className={classes.radioButtonWrapper}>
                <input
                  type="radio"
                  id="chatForm"
                  name="goal"
                  value="chat"
                  checked={choosedInput === "chat"}
                />
                <label htmlFor="chatForm">I just want to chat</label>
              </div>
              <div className={classes.radioButtonWrapper}>
                <input
                  type="radio"
                  id="testForm"
                  name="goal"
                  value="test"
                  checked={choosedInput === "test"}
                />
                <label htmlFor="testForm">I'm testing the form</label>
              </div>
            </div>
            <div className={classes.iconsWrapper}>
              <SocialLinks mobile={false} />
              <SocialLinks mobile={true} />
            </div>
          </div>
          <div
            style={{
              display: formName === "needRock" ? "block" : "none",
            }}
          >
            <NeedRockForm
              reCaptchaToken={reCaptchaToken}
              onReCaptchaVerify={reCaptchaVerify}
              setIsFormSentSuccessfully={setIsFormSentSuccessfully}
              setFormName={setFormName}
              setIsCrazyFormSend={setIsCrazyFormSend}
            />
          </div>
          <div
            style={{
              display: formName === "joinTeam" ? "block" : "none",
            }}
          >
            <FormSendCV
              setIsFormSentSuccessfully={setIsFormSentSuccessfully}
              setFormName={setFormName}
              setIsCrazyFormSend={setIsCrazyFormSend}
            />
          </div>
          <div
            style={{
              display: formName === "chat" ? "block" : "none",
            }}
          >
            <ChatForm
              reCaptchaToken={reCaptchaToken}
              onReCaptchaVerify={reCaptchaVerify}
              setIsFormSentSuccessfully={setIsFormSentSuccessfully}
              setFormName={setFormName}
              setIsCrazyFormSend={setIsCrazyFormSend}
            />
          </div>
          <div
            style={{
              display: formName === "test" ? "block" : "none",
            }}
          >
            <TestForm
              setIsFormSentSuccessfully={setIsFormSentSuccessfully}
              setFormName={setFormName}
              setIsCrazyFormSend={setIsCrazyFormSend}
              onClose={onClose}
            />
          </div>
        </>
      )}
    </div>
  )
}

ContactForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isFormSentSuccessfully: PropTypes.bool.isRequired,
  setIsFormSentSuccessfully: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  setFormName: PropTypes.func.isRequired,
}

export default ContactForm
