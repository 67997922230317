import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import TextInputWrapper from "src/components/forms/components/wrappers/TextInputWrapper"
import SubmitButton from "src/components/forms/components/inputs/SubmitButton"
import { superHeroes } from "src/components/forms/constants"
import TextAreaWrapper from "../../../components/wrappers/TextAreaWrapper"
import { useForm, FormProvider } from "react-hook-form"
import SelectInputWrapper from "../../../components/wrappers/SelectInputWrapper"
import classes from "./TestForm.module.scss"
import Nouislider from "react-nouislider"
import "../../../components/inputs/RangeInput/nouislider.scss"
import fingerClick from "../../../../../images/fingerClick.gif"
import html2canvas from "html2canvas"
import Calendar from "../../../../layout/Calendar/Calendar"
import SvgIcon from "../../../../SvgIcon"
import Close from "assets/icons/yellowClose.svg"
import HelperText from "../../../components/inputs/HelperText"
import { TimeClearError } from "../../../../../constants/functions"
import { useAnimation } from "../../../../../hooks/useAnimation"

const TestForm = ({
  setFormName,
  setIsFormSentSuccessfully,
  setIsCrazyFormSend,
  onClose,
}) => {
  const formMethods = useForm({ mode: "onChange" })

  const [madnessDegree, setMadnessDegree] = useState(" HaHaHa!")
  const [isDontClick, setIsDontClick] = useState(false)

  const [loadedFileName, setLoadedFileName] = useState("")
  const [fileError, setFileError] = useState("")
  const supportedExtension = [".jpeg", ".jpg", ".png"]

  const btnExplode = useRef(null)
  const gifClick = useRef(null)

  const {
    createParticleAtPoint,
    createParticleCanvas,
    updateAnimation,
  } = useAnimation()

  const handleDrop = useCallback(
    e => {
      e.preventDefault()
      const { dataTransfer } = e
      if (dataTransfer.items && dataTransfer.items[0].kind === "file") {
        const file = dataTransfer.items[0].getAsFile()
        checkFile(file.name)
      }
    },
    [checkFile],
  )

  const handleDropOver = e => {
    e.preventDefault()
  }

  const checkFile = useCallback(fileName => {
    const extension = fileName.slice(fileName.lastIndexOf("."))

    if (supportedExtension.includes(extension)) {
      fileName.length > 20
        ? setLoadedFileName(fileName.slice(0, 20) + "...")
        : setLoadedFileName(fileName)
      setFileError("")
    } else {
      setLoadedFileName("")
      setFileError("Error extension")
      setTimeout(() => {
        setFileError("")
      }, TimeClearError)
    }
  })

  useEffect(() => {
    const exploder = btnExplode.current

    html2canvas(document.querySelector("#root")).then(canvas => {
      canvas.getContext("2d")
      createParticleCanvas()
      exploder.addEventListener("click", handleClickExploderButton)
    })

    return () => {
      exploder.removeEventListener("click", handleClickExploderButton)
    }
  }, [])

  const handleClickExploderButton = () => {
    document.getElementById("root").style.borderColor = "rgb(0, 0, 0, 0.3)"
    setIsDontClick(true)
    gifClick.current.style.visibility = "visible"
    setTimeout(() => {
      formDisappearing()
    }, 1000)
  }

  const formDisappearing = () => {
    const form = document.querySelector("#root")
    const reductionFactor = 17
    const colors = [
      [255, 255, 255, 255],
      [34, 34, 34, 255],
      [255, 203, 8, 255],
      [138, 138, 138, 255],
    ]

    // Get the color data for our button
    const width = form.offsetWidth
    const height = form.offsetHeight

    // Keep track of how many times we've iterated (in order to reduce
    // the total number of particles create)
    let count = 0

    // Go through every location of our button and create a particle
    for (let localX = 0; localX < width; localX += 9) {
      for (let localY = 0; localY < height; localY += 9) {
        if (count % reductionFactor === 0) {
          const rgbaColorArr = colors[Math.floor(Math.random() * 4)]
          const bcr = form.getBoundingClientRect()
          const globalX = bcr.left + localX
          const globalY = bcr.top + localY

          createParticleAtPoint(globalX, globalY, rgbaColorArr)
        }
        count++
      }
    }
    form.style.opacity = 0

    handleCloseForm()

    setTimeout(() => {
      form.style.opacity = 100
      setIsDontClick(false)
      document.getElementById("root").style.borderColor = "white"
    }, 300)
  }

  const handleClickDeleteFile = e => {
    e.preventDefault()
    setLoadedFileName("")
  }

  const handleCloseForm = () => {
    setFormName("")
    onClose()
    if (typeof window !== `undefined`) {
      window.requestAnimationFrame(updateAnimation)
      gifClick.current.style.visibility = "hidden"
    }
  }

  const setFile = e => {
    if (e.currentTarget.files[0]) {
      checkFile(e.currentTarget.files[0].name)
    }
  }

  const changeMadness = values => {
    const value = +values[0]

    switch (value) {
      case 0:
        setMadnessDegree("Ha!")
        break
      case 25:
        setMadnessDegree("HaHa!")
        break
      case 50:
        setMadnessDegree("HaHaHa!")
        break
      case 75:
        setMadnessDegree("HaHaHaHa!")
        break
      case 100:
        setMadnessDegree("HaHaHaHaHa!")
        break
      default:
        setMadnessDegree("Ha!")
    }
  }

  const formSender = () => {
    setIsFormSentSuccessfully(true)
    setIsCrazyFormSend(true)
    setFormName("")
  }

  const inputRageElement = useMemo(
    () => (
      <Nouislider
        step={25}
        range={{ min: 0, max: 100 }}
        start={[0]}
        connect={[true, false]}
        onUpdate={changeMadness}
      />
    ),
    [],
  )

  const handleSubmit = e => {
    e.preventDefault()
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit} className={classes.formWrapper}>
        <img
          ref={gifClick}
          src={fingerClick}
          className={classes.DontClickImage}
          alt="fingerClick"
        />
        {isDontClick && <div className={classes.blackBlock}></div>}
        <TextInputWrapper
          type="text"
          name="WhatDo"
          label="What do you do?"
          placeholder="Enter what do you do"
        />
        <TextInputWrapper
          type="text"
          name="grandmotherName"
          label="Your grandmother's name"
          placeholder="Enter the name"
        />
        <SelectInputWrapper
          label="Who are you from marvel?"
          name="heroes"
          options={superHeroes}
        />
        <p className={classes.BlockHeader}>
          Do you like the smell of napalm in the morning?
        </p>
        <div className={classes.blockWrapper}>
          <div className={classes.radioButtonWrapper}>
            <input type="radio" id="likeNapalm" name="likeNapalm" value="Yes" />
            <label htmlFor="likeNapalm">Maybe Yes</label>
          </div>
          <div className={classes.radioButtonWrapper}>
            <input type="radio" id="hateNapalm" name="likeNapalm" value="Not" />
            <label htmlFor="hateNapalm">Maybe Not</label>
          </div>
        </div>
        <div className={classes.blockWrapper}>
          <p className={classes.BlockHeader}>I'm your father!</p>
          <input type="checkbox" className={classes.checkbox} id="isFather" />
          <label htmlFor="isFather" className={classes.checkboxLabel}>
            Nooooooooooooooooooooo!
          </label>
        </div>
        <div className={classes.blockWrapper}>
          <p className={classes.BlockHeader}>Pick a date, Marty!</p>
          <Calendar />
        </div>

        <div className={classes.blockWrapper}>
          <p className={classes.BlockHeader}>Are you crazy?</p>
          <div className={classes.sliderWrapper}>{inputRageElement}</div>
          <p className={classes.sliderSubscription}>{madnessDegree}</p>
        </div>

        <div className={classes.blockWrapper}>
          <p className={classes.BlockHeader}>Your photo from party!</p>
          <div
            className={classes.inputFileWrapper}
            style={{ borderColor: fileError ? "#F44336" : "#A1A1A1" }}
            onDrop={handleDrop}
            onDragOver={handleDropOver}
          >
            <input
              id="CV_uploader"
              type="file"
              className={classes.inputFile}
              accept={supportedExtension}
              onChange={setFile}
            />
            <label htmlFor="CV_uploader" className={classes.labelUpload}>
              {loadedFileName ? (
                <p>{loadedFileName}</p>
              ) : (
                <>
                  <p className={classes.description}>Drag & Drugs</p>
                  <p className={classes.description}>or</p>
                  <p className={classes.description}>don't spent our time!</p>
                </>
              )}
              <SvgIcon
                icon={Close}
                className={
                  loadedFileName ? classes.fileDeleter : classes.hideFileDeleter
                }
                size={"xs"}
                onClick={handleClickDeleteFile}
              />
            </label>
            {fileError && (
              <div className="pt-1">
                <HelperText isError={fileError} message={fileError} />
              </div>
            )}
          </div>
        </div>
        <TextAreaWrapper
          name="message"
          label="We don't care"
          maxLength={150}
          placeholder="Your text"
          control={formMethods.control}
        />
        <button ref={btnExplode} className={classes.DontClickButton}>
          Don't Click
        </button>
        <SubmitButton onClick={formSender}>Send</SubmitButton>
      </form>
    </FormProvider>
  )
}

TestForm.propTypes = {
  setIsFormSentSuccessfully: PropTypes.func.isRequired,
  setFormName: PropTypes.func.isRequired,
  setIsCrazyFormSend: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default TestForm
