import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import TextInputWrapper from "src/components/forms/components/wrappers/TextInputWrapper"
import SubmitButton from "src/components/forms/components/inputs/SubmitButton"
import { validation } from "src/components/forms/constants"
import { ClearAllErrors, clearErrorByName } from "src/constants/functions"
import TextAreaWrapper from "../../components/wrappers/TextAreaWrapper"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm, FormProvider } from "react-hook-form"
import classes from "../../Forms.module.scss"
import appConfig from "../../../../constants/appConfig"
import ErrorWrapper from "../../components/ScreenError"

const ChatForm = ({
  setFormName,
  setIsFormSentSuccessfully,
  reCaptchaToken,
  onReCaptchaVerify,
  setIsCrazyFormSend,
}) => {
  const formMethods = useForm({ mode: "onChange" })
  const [isError, setIsError] = useState(false)

  const submitSendForm = useCallback(
    async ({ name, email, message }) => {
      const formData = new FormData()
      const clearData = () => {
        formMethods.reset({})
      }
      formData.append("name", name)
      formData.append("email", email)
      formData.append("message", message)
      formData.append("typeForm", "chat")
      formData.append("g-recaptcha-response", message)
      try {
        const result = await fetch(
          `${process.env.GATSBY_HOST_URL}/scripts/contact.php`,
          {
            method: "POST",
            mode: "cors",
            body: formData,
          },
        )

        if (!result.ok) {
          throw new Error(`${result.status} ${result.statusText}`)
        }

        clearData()
        setFormName("")
        setIsFormSentSuccessfully(true)
        setIsCrazyFormSend(false)
      } catch (e) {
        setIsError(true)
      }
    },
    [
      formMethods,
      setFormName,
      setIsCrazyFormSend,
      setIsError,
      setIsFormSentSuccessfully,
    ],
  )

  return (
    <FormProvider {...formMethods}>
      <form
        style={{ display: isError ? "none" : "block" }}
        onSubmit={formMethods.handleSubmit(submitSendForm)}
        className={classes.formWrapper}
      >
        <TextInputWrapper
          type="text"
          name="name"
          label="What's your name?"
          placeholder="Enter your name"
        />
        <TextInputWrapper
          type="email"
          name="email"
          label="Enter your email"
          placeholder="user@example.com"
          validation={validation.email}
          onBlur={e => clearErrorByName(e, formMethods.clearErrors)}
        />
        <TextAreaWrapper
          name="message"
          label="Ask your question"
          maxLength={150}
          placeholder="Your text"
          validation={validation.message}
          control={formMethods.control}
        />
        <div className="mb-4">
          <ReCAPTCHA
            sitekey={appConfig.reCaptchaSiteKey}
            render="explicit"
            onChange={onReCaptchaVerify}
          />
        </div>
        <SubmitButton
          disabled={!reCaptchaToken}
          onClick={() => ClearAllErrors(formMethods.clearErrors)}
        >
          Let's Rock
        </SubmitButton>
      </form>
      <ErrorWrapper
        isError={isError}
        handleSubmit={() => {
          setIsError(false)
        }}
      />
    </FormProvider>
  )
}

ChatForm.propTypes = {
  onReCaptchaVerify: PropTypes.func.isRequired,
  reCaptchaToken: PropTypes.string,
  setIsFormSentSuccessfully: PropTypes.func.isRequired,
  setFormName: PropTypes.func.isRequired,
  setIsCrazyFormSend: PropTypes.func.isRequired,
}

ChatForm.defaultProps = {
  reCaptchaToken: null,
}

export default ChatForm
