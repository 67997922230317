import PropTypes from "prop-types"
import React, { memo, useEffect, useState } from "react"
import { Link } from "gatsby"
import classes from "./DefaultHeader.module.scss"
import OpenFormButton from "../OpenFormButton/OpenFormButton"
import NavItem from "src/components/layout/DefaultHeader/Components/NavItem/NavItem"
import clsx from "clsx"
import MediaQuery from "react-responsive"
import HaveIdea from "src/components/layout/TickerRun/components/HaveIdea/HaveIdea"
import DrumnCode from "src/assets/icons/logo.svg"
import SvgIcon from "../../SvgIcon"

const navigation = [
  {
    link: "/portfolio",
    title: "Portfolio",
  },
  {
    isNotLink: true,
    title: "Services",
    link: "/services",
    subMenu: [
      {
        link: "/services/web-development",
        title: "Web Development",
      },
      {
        link: "/services/mobile-development",
        title: "Mobile App Development",
      },
      {
        link: "/services/dedicated-developers",
        title: "Dedicated Developers",
      },
      {
        link: "/services/solutions-development",
        title: "Solutions Development",
      },
    ],
  },
  {
    link: "/agency",
    title: "Agency",
  },
  {
    link: "/careers",
    title: "Careers",
  },
]

const DefaultHeader = ({ className }) => {
  const [isActiveMenu, setActiveMenu] = useState(false)
  const [isShadow, setShadow] = useState(false)
  useEffect(() => {
    if (isActiveMenu) {
      document.body.classList.add("hide")
    } else {
      document.body.classList.remove("hide")
    }
  }, [isActiveMenu])
  return (
    <>
      <header className={`${classes.root} ${className}`}>
        <div className={classes.headerContainer}>
          <Link
            to="/"
            id="logoHeaderLink"
            aria-label="Go to Home Page"
            className={classes.logoLink}
          >
            <SvgIcon icon={DrumnCode} className={classes.logo} />
          </Link>
          <button
            className={`${
              isActiveMenu
                ? `${classes.burgerWrapper} ${classes["burgerWrapper__active"]}`
                : classes.burgerWrapper
            }`}
            onClick={() => {
              setActiveMenu(!isActiveMenu)
            }}
          />
          <nav
            className={`${
              isActiveMenu
                ? `${classes.headerMenu} ${classes["headerMenu__active"]}`
                : classes.headerMenu
            }`}
          >
            <ul
              className={`${
                isActiveMenu
                  ? `${classes.headerList} ${classes["headerList__active"]}`
                  : classes.headerList
              }  `}
            >
              {navigation.map(({ ...props }, index) => (
                <NavItem
                  key={index}
                  {...props}
                  setShadow={setShadow}
                  shadow={isShadow}
                />
              ))}
              <MediaQuery minWidth={768}>
                <li className={classes.headerItem}>
                  <OpenFormButton
                    title="Let’s Talk"
                    isHeader
                    isActiveMenu={isActiveMenu}
                  />
                </li>
              </MediaQuery>
            </ul>
            <MediaQuery maxWidth={768}>
              <HaveIdea
                title={false}
                text="Let’s Talk"
                className={classes.latsTalk}
              />
            </MediaQuery>
          </nav>
        </div>
        <div
          className={clsx(classes.shadow, { [classes.back]: isShadow })}
          onClick={() => {
            setShadow(false)
          }}
        />
      </header>
    </>
  )
}

DefaultHeader.propTypes = {
  className: PropTypes.string,
}

DefaultHeader.defaultProps = {
  className: "",
}

export default memo(DefaultHeader)
