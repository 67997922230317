import React, { memo } from "react"
import { Link } from "gatsby"
import classes from "./BasicFooter.module.scss"
import SocialLinks from "../../SocialLinks/SocialLinks"
import SoundAnimation from "images/audio.gif"

const BasicFooter = ({ hideSocials }) => {
  return (
    <div className={classes.container}>
      <footer className={classes.footerWrapper}>
        <nav className={classes.footerNavigation}>
          <div className={classes.footerLines}>
            <img
              src={SoundAnimation}
              className={classes.soundGif}
              alt="Sound Animation"
            />
          </div>

          <div className={classes.navigationListWrapper}>
            <h5 className={classes.navigationListTitle}>About</h5>
            <ul className={classes.navigationList}>
              <li className={classes.navigationItem}>
                <Link
                  id="footerPortfolio"
                  to={`/portfolio/`}
                  className={classes.navigationItemLink}
                >
                  Portfolio
                </Link>
              </li>
              <li className={classes.navigationItem}>
                <Link
                  id="footerAgency"
                  to={`/agency/`}
                  className={classes.navigationItemLink}
                >
                  Agency
                </Link>
              </li>
              <li className={classes.navigationItem}>
                <Link
                  id="footerCareers"
                  to={`/careers/`}
                  className={classes.navigationItemLink}
                >
                  Careers
                </Link>
              </li>
            </ul>
          </div>

          <div className={classes.navigationListWrapper}>
            <h5 className={classes.navigationListTitle}>Services</h5>
            <ul className={classes.navigationList}>
              <li className={classes.navigationItem}>
                <Link
                  to={`/services/web-development/`}
                  id="footerWebDevelopment"
                  className={classes.navigationItemLink}
                >
                  Web development
                </Link>
              </li>
              <li className={classes.navigationItem}>
                <Link
                  to={`/services/mobile-development/`}
                  className={classes.navigationItemLink}
                  id="footerMobileAppDevelopment"
                >
                  Mobile app development
                </Link>
              </li>
              <li className={classes.navigationItem}>
                <Link
                  to={`/services/dedicated-developers/`}
                  id="footerDedicatedDevelopers"
                  className={classes.navigationItemLink}
                >
                  Dedicated Developers
                </Link>
              </li>
              <li className={classes.navigationItem}>
                <Link
                  to={`/services/solutions-development/`}
                  id="footerSolutionsDevelopment"
                  className={classes.navigationItemLink}
                >
                  Solutions development
                </Link>
              </li>
            </ul>
          </div>

          <div className={classes.navigationListWrapper}>
            <h5 className={classes.navigationListTitle}>Contacts</h5>
            <ul className={classes.navigationList}>
              <li className={classes.navigationItem}>
                <div className={classes.location}>Ukraine, Zaporizhzhia</div>
              </li>
              <li className={classes.navigationItem}>
                <a
                  href="mailto:info@drumncode.com"
                  id="footerSendMail"
                  className={classes.navigationItemLink}
                >
                  info@drumncode.com
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <SocialLinks hide={hideSocials} mobile />

        <div className={classes.copyrightWrapper}>
          <p className={classes.copyright}>© DNC. All rights reserved 2023</p>
          <SocialLinks hide={hideSocials} mobile={false} />
        </div>
      </footer>
    </div>
  )
}

export default memo(BasicFooter)
