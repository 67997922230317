export const phoneRegex = /\+[0-9]{2}\s\([0-9]{3}\)\s[0-9]{3}\s[0-9]{2}\s[0-9]{2}/
export const phoneError = "The phone format is +38 (999) 999 99 99"
export const FileInputDefaultSubscription = "+ Add .PDF or .DOC"

export const validation = {
  name: {
    required: { value: true, message: "This field is required" },
  },
  email: {
    required: { value: true, message: "This field is required" },
  },
  message: {
    required: { value: true, message: "This field is required" },
  },
  phone: {
    required: { value: true, message: "This field is required" },
    pattern: {
      value: phoneRegex,
      message: phoneError,
    },
  },
  fileCV: {
    required: { value: true, message: "This field is required" },
  },
}

export const joinTheTeamFormValidation = {
  name: {
    required: { value: true, message: "This field is required" },
  },
  surname: {
    required: { value: true, message: "This field is required" },
  },
  email: {
    required: { value: true, message: "This field is required" },
  },
  vacancy: {
    required: { value: true, message: "This field is required" },
  },
  phone: {
    required: { value: true, message: "This field is required" },
  },
}

export const selectOptions = [
  { value: "needDev", label: "I need rock star developers" },
  { value: "needDesigner", label: "I need rock star designers" },
  { value: "joinTeam", label: "I want to join your awesome team" },
  { value: "chat", label: "I just want to chat" },
  { value: "testForm", label: "I'm testing the form" },
]

export const neededServices = [
  { label: "UX/UI Design" },
  { label: "Mobile app" },
  { label: "Web app" },
  { label: "Landing page" },
  { label: "E-commerce" },
  { label: "DevOps" },
  { label: "Help me decide!" },
]

export const superHeroes = [
  { value: "CaptainAmerica", label: "Captain America" },
  { value: "BlackWidow", label: "Black Widow" },
  { value: "Hulk", label: "Hulk" },
  { value: "Spider-man", label: "Spider-man" },
  { value: "Thor", label: "Thor" },
  { value: "DoctorStrange", label: "Doctor Strange" },
  { value: "IronMan ", label: "Iron Man" },
  { value: "Vision", label: "Vision" },
  { value: "Groot", label: "Groot" },
  { value: "CaptainMarvel", label: "Captain Marvel" },
]

export const yearsOfExperience = [
  { value: "< 0.6 year", label: "< 0.6 year" },
  { value: "< 1 year", label: "< 1 year" },
  { value: "< 3 year", label: "< 3 year" },
  { value: "< 5 year", label: "< 5 year" },
  { value: "> 5 year", label: "> 5 year" },
]

export const rangeInputPrices = ["~$10k", "~$50k", "~$100k", "~$500k", "~$1m"]
