export const monthsSelect = [
  { value: "January", label: "Jan" },
  { value: "February", label: "Feb" },
  { value: "March", label: "Mar" },
  { value: "April", label: "Apr" },
  { value: "May", label: "May" },
  { value: "June", label: "Jun" },
  { value: "July", label: "Jul" },
  { value: "August", label: "Aug" },
  { value: "September", label: "Sep" },
  { value: "October", label: "Oct" },
  { value: "November", label: "Nov" },
  { value: "December", label: "Dec" },
]
