import React, { memo, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { useFormContext } from "react-hook-form"

const FormInputWrapper = ({
  component: Component,
  helperText: helperTextProp,
  name,
  ...rest
}) => {
  const {
    register,
    errors,
    formState: { touched },
  } = useFormContext()

  const hasError = useMemo(() => !!errors[name], [errors, name])
  const isTouched = useMemo(() => !!touched[name], [name, touched])

  const helperText = useMemo(() => {
    if (!hasError) return helperTextProp
    if (hasError || isTouched) return errors[name].message
  }, [errors, hasError, helperTextProp, isTouched, name])

  return (
    <Component
      register={register}
      name={name}
      helperText={helperText}
      hasError={hasError}
      {...rest}
    />
  )
}

FormInputWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  helperText: PropTypes.string,
  name: PropTypes.string.isRequired,
}

FormInputWrapper.defaultProps = {
  helperText: null,
}

export default memo(FormInputWrapper)
