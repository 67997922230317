import React from "react"
import PropTypes from "prop-types"
import classes from "../components/inputs/Input.module.scss"

const ButtonTryAgain = ({ children, style, onClick }) => {
  return (
    <button
      type="submit"
      className={classes.submitBtn}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

ButtonTryAgain.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default ButtonTryAgain
