import React from "react"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"
import SvgIcon from "src/components/SvgIcon"
import Close from "src/assets/icons/close.svg"
import classes from "src/components/forms/Forms.module.scss"
import FormSentSuccessfully from "../components/ScreenFormSendedSuccessfully"
import SocialLinks from "../../SocialLinks/SocialLinks"
import FormSendCV from "../ContactForm/components/FormSendCV"

const VacancyForm = ({
  onClose,
  isFormSentSuccessfully,
  setIsFormSentSuccessfully,
}) => {
  const formMethods = useForm({ mode: "onChange" })

  const handleCloseForm = () => {
    onClose()
    clearData()
  }

  const clearData = () => {
    formMethods.reset({})
  }

  return (
    <div className={classes.formVacancyWrapper}>
      <div className={classes.closWrapper}>
        <SvgIcon
          icon={Close}
          className={classes.closeForm}
          onClick={handleCloseForm}
        />
      </div>
      {isFormSentSuccessfully ? (
        <FormSentSuccessfully />
      ) : (
        <div className={classes.formCVWrapper}>
          <FormSendCV setIsFormSentSuccessfully={setIsFormSentSuccessfully} />
        </div>
      )}
      <div className={classes.iconsWrapper}>
        <SocialLinks mobile={false} />
        <SocialLinks mobile={true} />
      </div>
    </div>
  )
}

VacancyForm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isFormSentSuccessfully: PropTypes.bool.isRequired,
  setIsFormSentSuccessfully: PropTypes.func.isRequired,
}

export default VacancyForm
