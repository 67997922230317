import React, { useContext } from "react"
import PropTypes from "prop-types"
import Button from "src/components/Button/Button"
import { FormOpenContext } from "../BasicLayout/BasicLayout"
import classes from "./OpenFormButton.module.scss"
const OpenFormButton = ({
  title,
  isActiveMenu,
  isOpenCV,
  stickyMobile,
  isHeader,
}) => {
  const { handleOpenContactForm } = useContext(FormOpenContext)
  return (
    <>
      {stickyMobile ? (
        <div className={classes.bottomBar}>
          <button
            id={"button" + title.replace(/\s/g, "")}
            className={classes.openFormBtn}
            onClick={
              isOpenCV
                ? () => handleOpenContactForm(true)
                : () => handleOpenContactForm(false)
            }
          >
            {title}
          </button>
        </div>
      ) : (
        <Button
          className={isHeader && classes.yellowLink}
          id={"button" + title.replace(/\s/g, "")}
          onClick={
            isOpenCV
              ? () => handleOpenContactForm(true)
              : () => handleOpenContactForm(false)
          }
          isActive={isActiveMenu}
        >
          {title}
        </Button>
      )}
    </>
  )
}

OpenFormButton.propTypes = {
  title: PropTypes.string,
  isActiveMenu: PropTypes.bool,
  isOpenCV: PropTypes.bool,
}

OpenFormButton.defaultProps = {
  isActiveMenu: false,
  isOpenCV: false,
}

export default OpenFormButton
