export const useAnimation = () => {
  let particleCanvas, particleCtx
  let particles = []

  const createParticleAtPoint = (x, y, colorData) => {
    const particle = new ExplodingParticle()
    particle.rgbArray = colorData
    particle.startX = x
    particle.startY = y
    particle.startTime = Date.now()
    particles.push(particle)
  }

  const ExplodingParticle = function () {
    // Set how long we want our particle to animate for
    this.animationDuration = 5000 // in ms

    // Set the speed for our particle
    this.speed = {
      x: (-5 + Math.random() * 10) / 1.5,
      y: (-5 + Math.random() * 10) / 1.5,
    }

    // Size our particle
    this.radius = 5 + Math.random() * 7

    // Set a max time to live for our particle
    this.life = this.animationDuration
    this.remainingLife = this.life

    // This function will be called by our animation logic later on
    this.draw = ctx => {
      const p = this

      if (this.remainingLife > 0 && this.radius > 0) {
        // Draw a circle at the current location
        ctx.beginPath()
        ctx.arc(p.startX, p.startY, p.radius, 0, Math.PI * 2)
        ctx.fillStyle =
          "rgba(" +
          this.rgbArray[0] +
          "," +
          this.rgbArray[1] +
          "," +
          this.rgbArray[2] +
          ", 1)"
        ctx.fill()

        // Update the particle's location and life
        p.remainingLife--
        p.radius -= 0.15
        p.startX += p.speed.x
        p.startY += p.speed.y
      }
    }
  }
  const createParticleCanvas = () => {
    // Create our canvas
    particleCanvas = document.createElement("canvas")
    particleCtx = particleCanvas.getContext("2d")

    // Size our canvas
    particleCanvas.width = window?.innerWidth
    particleCanvas.height = window?.innerHeight

    // Position out canvas
    particleCanvas.style.position = "fixed"
    particleCanvas.style.top = "0"
    particleCanvas.style.left = "0"

    // Make sure it's on top of other elements
    particleCanvas.style.zIndex = "100001"

    // Make sure other elements under it are clickable
    particleCanvas.style.pointerEvents = "none"

    // Add our canvas to the page
    document.body.appendChild(particleCanvas)
  }

  const updateAnimation = () => {
    if (particleCtx) {
      particleCtx.clearRect(0, 0, window?.innerWidth, window.innerHeight)
    }
    if (particles.every(item => item.radius < 0)) {
      return
    }

    // Draw all of our particles in their new location
    for (let i = 0; i < particles.length; i++) {
      if (particles[i].radius > 0) {
        particles[i].draw(particleCtx)
      } else {
        continue
      }

      // Simple way to clean up if the last particle is done animating
      if (i === particles.length - 1) {
        let percent =
          (Date.now() - particles[i].startTime) / particles[i].animationDuration
        if (percent > 1) {
          particles = []
        }
      }
    }

    if (typeof window !== `undefined`) {
      window.requestAnimationFrame(updateAnimation)
    }
  }

  return {
    createParticleAtPoint,
    createParticleCanvas,
    updateAnimation,
  }
}
