import React, { useCallback, useEffect, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import classes from "../Input.module.scss"
import Nouislider from "react-nouislider"
import "./nouislider.scss"
import { rangeInputPrices } from "../../../constants"

const RangeInput = ({ setBudget, startBudget, label }) => {
  const prices = [0, 25, 50, 75, 100]
  const ref = useRef(null)

  const onChange = useCallback(values => {
    const highlightedElements = prices.indexOf(+values[0])
    setBudget(rangeInputPrices[highlightedElements])
    const markers = document.querySelectorAll(".noUi-marker-large")
    const valueLarge = document.querySelectorAll(".noUi-value-large")
    for (let i = 0; i < markers.length; i++) {
      if (i > highlightedElements) {
        valueLarge[i].style.color = "#a1a1a1"
        markers[i].style.background = "rgb(211,211,211)"
      } else {
        valueLarge[i].style.color = "rgba(34,34,34,1)"
        markers[i].style.background = "#ffcb08"
      }
    }
  }, [])

  const inputRangeElement = useMemo(
    () => (
      <Nouislider
        ref={ref}
        pips={{
          mode: "positions",
          values: [0, 25, 50, 75, 100],
          density: 50,
        }}
        snap={true}
        range={{
          min: prices[0],
          "25%": prices[1],
          "50%": prices[2],
          "75%": prices[3],
          max: prices[4],
        }}
        onUpdate={onChange}
        start={[0]}
        connect={[true, false]}
      />
    ),
    [],
  )

  const handleChangeClick = i => () => {
    if (ref.current) {
      ref.current.slider.set(prices[i])
    }
  }

  useEffect(() => {
    const valueLarge = document.querySelectorAll(".noUi-value-large")
    const markers = document.querySelectorAll(".noUi-marker")
    valueLarge.forEach((item, i) => {
      valueLarge[i].innerText = rangeInputPrices[i]
      valueLarge[i].addEventListener("click", handleChangeClick(i))
      valueLarge[i].style.cursor = "pointer"
      markers[i].style.cursor = "pointer"
      markers[i].addEventListener("click", handleChangeClick(i))
    })
  })

  return (
    <div className={classes.rootRangeInput}>
      {label && <label className={classes.labelRangeInput}>{label}</label>}
      <div className={classes.wrapperRangeInput}>{inputRangeElement}</div>
    </div>
  )
}

RangeInput.propTypes = {
  label: PropTypes.string.isRequired,
  startBudget: PropTypes.number.isRequired,
  setBudget: PropTypes.func.isRequired,
}

export default RangeInput
