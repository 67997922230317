import React from "react"
import PropTypes from "prop-types"
import classes from "./Input.module.scss"
import { useFormContext } from "react-hook-form"
import classNames from "classnames"

const SubmitButton = ({ children, disabled, style, onClick, isDark }) => {
  const {
    formState: { isSubmitting, isValidating },
  } = useFormContext()

  return (
    <button
      type="submit"
      className={classNames(classes.submitBtn, isDark && classes.isDark)}
      disabled={disabled || isSubmitting || isValidating}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isDark: PropTypes.bool,
}

SubmitButton.defaultProps = {
  disabled: false,
}

export default SubmitButton
