import React, { memo, useContext } from "react"
import classes from "./HaveIdea.module.scss"
import { FormOpenContext } from "../../../BasicLayout/BasicLayout"
import clsx from "clsx"

const HaveIdea = ({
  isOpenCV,
  text,
  title = "Do you have an idea?",
  none,
  className,
}) => {
  const { handleOpenContactForm } = useContext(FormOpenContext)
  return (
    <div className={clsx(none ? "d-none" : classes.wrapper, className)}>
      {title && (
        <p
          className={classes.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      <button
        id={"buttonMobile" + text.replace(/\s/g, "")}
        className={classes.btn}
        onClick={
          isOpenCV
            ? () => handleOpenContactForm(true)
            : () => handleOpenContactForm(false)
        }
      >
        {text}
      </button>
    </div>
  )
}

export default memo(HaveIdea)
