import React, { memo } from "react"
import classes from "../Forms.module.scss"
import Image from "../../image"
import PropTypes from "prop-types"

const FormSentSuccessfully = ({ imageName, subscription }) => {
  return (
    <div className={classes.successWrapper}>
      <Image src={imageName} className={classes.pictureSuccess} />
      <h3 className={classes.successDescription}>{subscription}</h3>
    </div>
  )
}

FormSentSuccessfully.propTypes = {
  imageName: PropTypes.string,
  subscription: PropTypes.string,
}

FormSentSuccessfully.defaultProps = {
  imageName: "success.png",
  subscription: "Your message has been sent!",
}

export default memo(FormSentSuccessfully)
