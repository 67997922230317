import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import classes from "src/components/layout/DefaultHeader/DefaultHeader.module.scss"
import { Link } from "gatsby"

import HeaderSubMenu from "../HeaderSubMenu/HeaderSubMenu"
import clsx from "clsx"
import { useMediaQuery } from "react-responsive"

const NavItem = ({ link, title, subMenu, isNotLink, shadow, setShadow }) => {
  const [active, setActive] = useState(false)

  const isCurrentActive = useMemo(() => {
    if (typeof window !== `undefined`) {
      return window.location.pathname.includes(link)
    }
    return false
  }, [link])

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })
  const onHover = useCallback(() => {
    setShadow && isNotLink && !isTabletOrMobile && setShadow(!shadow)
  }, [shadow, isNotLink, isTabletOrMobile])

  const onClick = useCallback(() => {
    if (isNotLink && isTabletOrMobile) {
      setShadow(!shadow)
      setActive(!active)
    }
  }, [active, shadow, isNotLink, isTabletOrMobile])
  useEffect(() => {
    if (isCurrentActive) {
      setActive(true)
    }
  }, [isCurrentActive])
  return (
    <li
      className={clsx(classes.headerItem, { [classes.disabled]: isNotLink })}
      onClick={onClick}
      onMouseOver={onHover}
      onMouseOut={onHover}
    >
      {isNotLink ? (
        <Link
          className={clsx(classes.headerLink, {
            [classes.headerLinkActive]: active || isCurrentActive,
          })}
          to="/#"
        >
          {title}
        </Link>
      ) : (
        <Link
          id={"header" + title.replace(/\s/g, "")}
          partiallyActive
          to={link}
          disabled={isNotLink}
          activeClassName={classes.headerLinkActive}
          className={classes.headerLink}
        >
          {title}
        </Link>
      )}
      {subMenu && <HeaderSubMenu active={active} subMenu={subMenu} />}
    </li>
  )
}

export default memo(NavItem)
